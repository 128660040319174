import React, { Component } from "react";

class AboutTwo extends Component {



render() {
    let title = 'Tarixçəmiz',
        description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,';
    return (
        <React.Fragment>





            <div className="about-wrapper">
                <div className="container container-grid">

                    <div>
                    </div>
                    <div className="grid-1">
                        <div className="thumbnail thumbnail-grid">
                            <img className="w-100" src="/assets/images/brand/v11.jpg" alt="Brand Images" />
                            
                        </div>
                    </div>


                    <div className="section-title">
                        <h2 className="title title-h2">{title}</h2>
                        <p className="description">{description}</p>
                    </div>


                    <div className="thumbnail olive-end ">
                        <img src="/assets/images/brand/zeytun.png" alt="Brand Images" />
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}
}
export default AboutTwo;
