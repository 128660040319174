import React, { Component, Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';




class CounterOne extends Component {
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    }
    render() {
        let Data = [
            {
                countNum: 199,
                countTitle: 'İşçi',
            },
            {
                countNum: 575,
                countTitle: 'Market',
            },
            {
                countNum: 69,
                countTitle: 'Ölkəyə İxrac',
            },
        ];

        return (
            <Fragment>


                <div className="grid-template">

                    <div className="counter-img-content-top">
                        <img src="/assets/images/brand/yag3.png" alt="Brand images" />
                    </div>

                    <div className="row row-numb">

                        <div>

                        </div>

                        {Data.map((value, index) => (
                            <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                                <h5 className="counter">
                                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                        <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                    </VisibilitySensor>
                                </h5>
                                <p className="description">{value.countTitle}</p>
                            </div>
                        ))}



                    </div>
                    <div className="counter-img-content-bottom">
                        <img src="/assets/images/brand/yag2.png" alt="Brand images" />
                    </div>
                </div>
                <div className="vector">
                    <img src="/assets/images/brand/vector.png" alt="" />
                </div>
            </Fragment>
        )
    }
}
export default CounterOne;