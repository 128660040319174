import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import ModalVideo from 'react-modal-video';
import { videoTagString, VideoTag } from 'react-video-tag';
import Helmet from "../component/common/Helmet";


videoTagString({ src: '/assets/images/service/video1.mp4', poster: '/assets/images/bg/bg-image-24.jpg' })

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'ZƏNGİN VƏ TƏBİİ DADI İLƏ',
        description: '"Violetto" məhsulları ilə ölkəmizin təbii məhsullarının təamlarının dadını hiss edəcəksiniz.',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]

class StudioAgency extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        const PostList = BlogContent.slice(0, 3);
        return (
            <Fragment>
                <Helmet pageTitle="Ana Səhifə" />
                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div className="slide slide-style-2 slider-video-bg d-flex  align-items-center justify-content-center " key={index} >
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-8">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                            {value.description ? <p className="description description-main">{value.description}</p> : ''}
                                        </div>
                                    </div>
                                    <div className="col-lg-4  ">
                                        <div className="video-inner ">
                                            <ModalVideo  channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({ isOpen: false })} />
                                            <button className="video-popup theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="video-background">
                                <VideoTag className='w-100'  autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`} src={`${"/assets/images/service/video1.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}


                </div>
                {/* End Slider Area   */}


                {/* Start About Area */}
                <div className="about-area ">
                    <AboutTwo />
                </div>
                {/* End About Area */}

                {/* Start Service Area  */}
                <div className="service-area ptb--80 ">
                    <div className="container container-service">
                        <ServiceTwo />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-img-content">
                        <img src="/assets/images/brand/vector-milk.png" alt="" />
                    </div>
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--30">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area">
                    <div className="container container-counter">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight800">STATİSTİKA</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />



                    </div>
                </div>
                {/* End CounterUp Area */}




                {/* Start Blog Area */}
                <div className="rn-blog-area pt--120 bg_color--1">
                    <div className="container container-blog">




                        <div className="row align-items-end">
                            <div className="col-lg-6">
                                <div className="section-title text-left">
                                    <h2>Fabrik</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <a className="btn-transparent rn-btn-dark" href="/blog"><span className="text">Daha Çox Gör</span></a>
                                </div>
                            </div>
                        </div>



                        <div className="blog-component">

                            <div className="blog-img-component">
                                <img src="/assets/images/brand/olive-oil.png" alt="" />
                            </div>



                            <div className="row mt--60 mt_sm--30">
                                {PostList.map((value, i) => (
                                    <div className="col-lg-4 col-md-6 col-12" key={i}>
                                        <div className="blog blog-style--1">
                                            <div className="thumbnail">
                                                <a href="/blog-details">
                                                    <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images" />
                                                </a>
                                            </div>
                                            <div className="content">
                                                <p className="blogtype">{value.category}</p>
                                                <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                                <div className="blog-btn">
                                                    <a className="rn-btn text-white" href="/blog-details"> Daha Ətraflı</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
                {/* End Blog Area */}

                {/* Start Brand Area */}

                <div className="container-brand">

                    <div className="brand-img-content">
                        <img src="/assets/images/brand/bottle.png" alt="" />
                    </div>

                    <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                        <div className="container container-partners">
                            <h1>PARTNYORLAR</h1>
                            <div className="row">
                                <div className="col-lg-12">
                                    <BrandTwo />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="last-olive-img">
                        <img src="/assets/images/brand/olives.png" alt="" />
                    </div>

                </div>

                {/* End Brand Area */}

                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default StudioAgency;