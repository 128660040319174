const BlogContent = [
    {
        images: '01',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    },
    {
        images: '02',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    },

    {
        images: '03',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    },
    {
        images: '04',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    },
    {
        images: '05',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    },
    {
        images: '06',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    },
    {
        images: '07',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    },
    {
        images: '08',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    },
    {
        images: '09',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    },
    {
        images: '10',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    }, {
        images: '11',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    }, {
        images: '12',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    }, {
        images: '13',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    }, {
        images: '08',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    }, {
        images: '11',
        title: 'Məhsulun Adı',
        category: 'Kateqoriya'
    },
    
]

export default BlogContent;