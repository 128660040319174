import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Tərkibi',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiLayers />,
        title: 'Məhsul Növləri',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiUsers />,
        title: 'Vitamin Kompleksi',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiMonitor />,
        title: 'İstifadəçi Məmnuniyyəti',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    }
]

class ServiceTwo extends Component {
    render() {
        // let title = 'Services',
        // description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.';
        return (
            <React.Fragment>
                <div className="empty">

                </div>
              
              
                <div className="row row-around">
                    {/* <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div> */}

                    <div className="">
                        <div className="row service-one-wrapper">
                            {ServiceList.map((val, i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon icon-main">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title icon-text-main">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="img-content">
                    <img src="/assets/images/brand/yag.png" alt="Brand Images" />
                </div>

            </React.Fragment>
        )
    }
}
export default ServiceTwo;
